.newscard-wrapper {
  background: #312f69;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 12px;
  /* border: 1px solid #141B44; */
  padding: 10px;
  /* width: 24%; */
  transition: all .25s;
  cursor: pointer;
}

.newscard-wrapper:focus,
.newscard-wrapper:hover,
.newscard-wrapper:active{
  background: #1E1C40;
}


.news-shadow{
  position: absolute;
  width: 100%;
  bottom: 0;
}

.news-date{
  position: absolute;
  bottom: 5px;
  left: 5px;
}
.newsimg {
  border-radius: 12px 12px 0px 0px;
  height: 100px;
  width: 100%;
  object-fit: cover;
}

.newsimg2 {
    border-radius: 12px;
    height: 150px;
    width: 100%;
    object-fit: fill;
    max-width: 200px;
}

.ncarrow {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0px;
  bottom: -5px;
}

.nc-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #b5bbdf;
  position: relative;
  bottom: 12px;
}

.nc-hot-trend {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.018em;
  color: #f09c3a;
  text-shadow: 0px 2px 4px rgba(240, 156, 58, 0.4);
  display: flex;
  gap: 10px;
  align-items: center;
}

.nc-date {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: -0.018em;
  color: #c1ccf8;
  display: flex;
  gap: 5px;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .newscard-wrapper{
    width: 100% !important; 
  }
  
  .newsimg2{
    width: 100% !important;
    max-width: 100% !important;
  }
}
