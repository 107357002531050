/* AUDIT SECTION */
.sectionaudit {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 4rem;
  max-width: 1900px;
}

@media screen and (max-width: 1150px) {
  .sectionaudit {
    display: block;
  }
}

.sectionaudit-left {
  width: 50%;
  margin-right: 1rem;
}

@media screen and (max-width: 1150px) {
  .sectionaudit-left {
    width: 100%;
    margin-right: 0;
  }
}

.sectionaudit-right {
  width: 50%; 
  margin-left: 1rem;
}

@media screen and (max-width: 1150px) {
  .sectionaudit-right {
    width: 100%; 
    margin-left: 0;
    margin-top: 2rem;
  }
}

/* AUDIT COMPONENT */
.audit {
  background-color: var(--color-gunmetal);
  border-radius: var(--border-radius-large);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: var(--shadow-black);
}

.audit-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.audit-header-options {
  display: flex;
  justify-content: flex;
  align-items: center;
  height: 38px; width: 100%;
}

.audit-header-options-chain {
  height: 100%; width: 70px;
  position: relative;
  border-radius: var(--border-radius) 0 0 var(--border-radius);
  border: 1px solid rgba(255, 255, 255, 0.05);
  cursor: pointer;
  padding: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-onyx);
}

.audit-header-options-chain-img {
  height: 100%;
  border-radius: 50%;
  display: block;
}

.audit-header-options-chain-chains {
  position: absolute;
  top: calc(100% + .5rem); left: 0;
  height: 0; width: 200px;
  transition: all .2s ease;
  border-radius: var(--border-radius);
  overflow: hidden;
  overflow-y: scroll;
  background-color: var(--color-onyx);
  border: 0px solid rgba(255, 255, 255, 0.05);
  box-shadow: var(--shadow-black);
  z-index: 2;
}

.audit-header-options-chain-chainsopen {
  height: 240px;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.audit-header-options-chain-chains-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: .7rem;
}

.audit-header-options-chain-chains-item:hover {
  background-color: var(--color-gunmetal);
}

.audit-header-options-chain-chains-item-img {
  display: block;
  width: 20px; height: 20px;
  object-fit: cover;
  border-radius: 50%;
}

.audit-header-options-chain-chains-item-name {
  margin-left: 1rem;
  color: white;
}

.audit-header-options-input {
  height: 100%; width: 100%;
  display: block;
  margin-right: 1rem;
  font-size: 18px;
  background-color: transparent;
  color: white;
  border-radius: 0 var(--border-radius) var(--border-radius) 0 ;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-left: none;
  padding: .5rem 1rem;
}

.audit-header-options-audit {
  height: 100%; width: 200px;
  border-radius: var(--border-radius);
  font-size: 18px; font-weight: 600;
  cursor: pointer;
  background-color: var(--color-azure);
  box-shadow: var(--shadow-azure);
  color: white;
}

.audit-header-options-auditdisabled {
  color: rgba(255, 255, 255, 0.6);
  background-color: var(--color-charleston);
  cursor: auto;
  box-shadow: none;
}

.audit-header-options-audit-loadingicon {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotateZ(0rad);
  }

  to {
    transform: rotateZ(6.28rad);
  }
}

.audit-header-options-audit-loadingicon > svg {
  width: 20px; height: 20px;
}

.audit-body {
  padding: 1rem;
  color: white;
}

.audit-body-token {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.audit-body-token-left {

}

.audit-body-token-left-name {
  font-size: 18px;
}

.audit-body-token-left-nameplaceholder {
  width: 100px; height: 20px;
  border-radius: var(--border-radius);
  background-color: var(--color-charleston);
}

.audit-body-token-left-address {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}

.audit-body-token-right {
  font-size: 18px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audit-body-token-rightplaceholder {
  width: 60px; height: 20px;
  border-radius: var(--border-radius);
  background-color: var(--color-charleston);
}

.audit-body-token-right > span {
  font-weight: 600;
  margin-right: .5rem;
}

.audit-body-score {
  margin-top: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@media screen and (max-width: 950px) {
  .audit-body-score {
    margin-top: 2rem;
    display: block;
  }
}

.audit-body-score-left {

}

@media screen and (max-width: 950px) {
  .audit-body-score-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.audit-body-score-right {
  margin-left: 3rem;
  font-size: 18px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
  width: 100%;
}

@media screen and (max-width: 950px) {
  .audit-body-score-right {
    margin-left: 0;
    text-align: center;
    margin-top: 2rem;
  }
}

.audit-body-score-right-placeholder {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media screen and (max-width: 950px) {
  .audit-body-score-right-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.audit-body-score-right-placeholder > * {
  background-color: var(--color-charleston);
  height: 16px;
  border-radius: var(--border-radius);
  margin-right: .5rem;
  margin-bottom: .5rem;
}

.audit-body-score-right-placeholder-textsmall {
  width: 40px;
}

.audit-body-score-right-placeholder-textmedium {
  width: 60px;
}

.audit-body-score-right-placeholder-textlarge {
  width: 110px;
}

.audit-body-bars {
  margin-top: 2rem;
}

.audit-body-bars-warninglabel {
  font-size: 18px; font-weight: 600;
  display: block;
  color: rgba(255, 255, 255, 0.7);
}

.audit-body-bars-warning {
  margin-top: .5rem;
  background-color: var(--color-charleston);
  width: 100%; height: 18px;
  border-radius: var(--border-radius);
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.audit-body-bars-warning-bar {
  position: absolute;
  top: 0; left: 0;
  width: 0; height: 100%;
  background-color: var(--color-red);
  transition: all 3s ease;
}

.audit-body-bars-passedlabel {
  font-size: 18px; font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 1rem;
  display: block;
}

.audit-body-bars-passed {
  margin-top: .5rem;
  position: relative;
  background-color: var(--color-charleston);
  width: 100%; height: 18px;
  border-radius: var(--border-radius);
  border-radius: 12px;
  overflow: hidden;
}

.audit-body-bars-passed-bar {
  position: absolute;
  top: 0; left: 0;
  width: 0; height: 100%;
  background-color: var(--color-azure);
  transition: all 3s ease;
}

.audit-body-analytics {
  margin-top: 2rem;
}

.audit-body-analytics-title {
  font-size: 18px; font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
}

.audit-body-analytics-list {
  margin-top: 1rem;
  max-height: 800px;
  overflow: hidden;
  overflow-y: scroll;
}

.audit-body-analytics-item {
  border: 1px solid rgba(255, 255, 255, 0.05);
  border: none;
  background-color: var(--color-charleston);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: .6rem;
  margin-bottom: .6rem;
  font-size: 17px;
  color: rgba(255, 255, 255, 0.7);
  border-radius: var(--border-radius);
  transition: all .4s ease;
  opacity: 0;
  transform: scale(.7);
}

.audit-body-analytics-itemplaceholder {
  margin-bottom: .6rem;
  padding: .6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--border-radius);
}

.audit-body-analytics-itemanimation {
  opacity: 1;
  transform: scale(1);
}

.audit-body-analytics-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.audit-body-analytics-item-iconplaceholder {
  height: 17px; width: 17px;
  background-color: var(--color-charleston);
  border-radius: var(--border-radius);
}

.audit-body-analytics-item-icon > svg {
  width: 17px; height: 17px;
  color: var(--color-red);
}

.audit-body-analytics-item-iconpassed > svg {
  color: var(--color-green);
}

.audit-body-analytics-item-desc {
  margin-left: 1rem; 
}

.audit-body-analytics-item-descplaceholder {
  margin-left: 1rem; 
  height: 17px; width: 200px;
  background-color: var(--color-charleston);
  border-radius: var(--border-radius);
}

.audit-body-info {
  margin-top: 2rem;
}

.audit-body-info-title {
  font-size: 18px; font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
}

.audit-body-info-list {
  margin-top: 1rem;
  max-height: 800px;
  overflow: hidden;
  overflow-y: scroll;
}

.audit-body-info-item {
  background-color: var(--color-charleston);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .6rem;
  margin-bottom: .6rem;
  font-size: 17px;
  color: rgba(255, 255, 255, 0.7);
  border-radius: var(--border-radius);
  transition: all .4s ease;
  opacity: 0;
  transform: scale(.7);
}

.audit-body-info-itemanimation {
  opacity: 1;
  transform: scale(1);
}

.audit-body-info-itemplaceholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .6rem;
  margin-bottom: .6rem;
}

.audit-body-info-item-key {

}

.audit-body-info-item-keyplaceholder {
  height: 17px; width: 68px;
  background-color: var(--color-charleston);
  border-radius: var(--border-radius);
}

.audit-body-info-item-value {

  display: flex;
  justify-content: center;
  align-items: center;
}

.audit-body-info-item-value-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: .5rem;
  cursor: pointer;
}

.audit-body-info-item-valueplaceholder {
  height: 17px; width: 60px;
  background-color: var(--color-charleston);
  border-radius: var(--border-radius);
}

/* LATEST AUDITS COMPONENT */
.latestaudits {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: var(--color-gunmetal);
  border-radius: var(--border-radius-large);
}

.latestaudits-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  font-weight: 600; font-size: 18px;
  color: white;
}

.latestaudits-body {
  padding: 1rem;
}

.latestaudits-body-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  background-color: var(--color-charleston);
  border-radius: var(--border-radius);
  padding: .6rem .8rem;
  color: white;
  height: 58px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all .5s ease;
}

.latestaudits-body-itemanimation {
  animation: slide .4s ease;
}

@keyframes slide {
  from {
    transform: scale(.6);
  }

  to {
    transform: scale(1);
  }
}

.latestaudits-body-item-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.latestaudits-body-item-left-img {
  display: block;
  object-fit: cover;
  border-radius: 50%;
  height: 100%;
}

.latestaudits-body-item-left-info {
  margin-left: 1rem;
}

.latestaudits-body-item-left-info-symbol {
  font-weight: 600;
}

.latestaudits-body-item-left-info-name {

}

.latestaudits-body-item-date {

}

@media screen and (max-width: 650px) {
  .latestaudits-body-item-date {
    display: none;
  }
}

.latestaudits-body-item-view {
  border-radius: var(--border-radius);
  background-color: var(--color-azure);
  background-color: var(--color-onyx);
  color: white;
  padding: 0 2rem;
  height: 100%;
  font-weight: 5  00; font-size: 18px;
  cursor: pointer;
}

@media screen and (max-width: 650px) {
  .latestaudits-body-item-view {
    padding: 0 1.4rem;
  }
}


:root {
  --max-width: 1100px;
  --border-radius: 6px;
  --border-radius-small: 6px;
  --border-radius-large: 9px;
  --border-radius-larger: 16px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );

  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  /* components */
  --comp-aside-width: 230px;

  /* shadows */
  --shadow-azure: #0080ff9a 0px 0px 12px;
  --shadow-black: #0000002f 0px 0px 14px 0px;
  --shadow-yellow: #cfc64783 0px 0px 12px;

  /* colors */
  
  /* grey */
  --color-charleston: #24292E;
  --color-gunmetal: #2B3137;
  --color-onyx: #343A41;
  --color-arsenic: #3B4248;

  /* red/pink */
  --color-paradise: #EC365E;
  --color-american-red: #B31942;

  /* blue */
  --color-azure: #0080FF;

  /* purple */
  --color-vivid-purple: #6A0F8E;
  --color-veronica: #A020F0;

  /* yellow/white */
  --color-cream: #FFFDD0;

  --color-purple: rgb(237, 39, 255);
  --color-white: rgb(255, 255, 255);
  --color-grey: rgba(255, 255, 255, 0.7);
  --color-green: rgb(75, 202, 75);
  --color-red:  rgb(255, 39, 86);
  --color-yellow: #cfc547;
  --color-blue: rgb(39, 125, 255);
}
