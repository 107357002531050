.utilities-grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;

    @media screen and (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 786px) {
    grid-template-columns: repeat(1, 1fr);
    }
}

.utility-card{
    border-radius: 8px;
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
    height: 100%;
    // width: 450px;
    height: auto;
    overflow: hidden;
    position: relative;
    // filter: grayscale(0.5);
    transition: all .35s;
    --mask: linear-gradient(-135deg, #0000 50px, #000 0);
  -webkit-mask: var(--mask);
  mask: var(--mask);
    aspect-ratio: 16/9;

}


.eth-item-active2 {
  background: linear-gradient(277.84deg, #5871d2 0.89%, #1e1c40 92.83%);
  box-shadow: 0px 2px 12px rgba(78, 73, 197, 0.22);
}

.bsc-item-active2 {
  background: linear-gradient(277.84deg, #f6851a 0.89%, #1e1c40 92.83%);
  box-shadow: 0px 2px 12px rgba(220, 173, 12, 0.22);
}

.utility-title{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
    margin-bottom: 0;
    @media screen and (max-width: 786px) {
    font-size: 26px;

    }
}

.utility-desc{
        font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    text-align: center;
    @media screen and (max-width: 786px) {
        font-size: 10px;
        line-height: 14px;
        }
}

.utility-first{
    position: absolute;
    transition: all .35s;
    opacity: 1;
}

.utility-desc-div{
    transition: all .35s;
    position: absolute;
    width: 90%;
    opacity: 0;
    transform: translateY(300px);
}

.mainnet-card{
 background-image: url('./mainnet.webp');
 background-size: 100% 100%;
 transition: all .35s;
}

.stats-card{
background-image: url('./stats.webp');
 background-size: 100% 100%;
 transition: all .35s;
}

.integrations-card{
background-image: url('./nftIntegrations.webp');
 background-size: 100% 100%;
 transition: all .35s;
}

.coins-card{
background-image: url('./utilities.webp');
 background-size: 100% 100%;
 transition: all .35s;
}

.events-card{
background-image: url('./premiumEvents.webp');
 background-size: 100% 100%;
 transition: all .35s;
}
.partners-card{
background-image: url('./partners.webp');
 background-size: 100% 100%;
 transition: all .35s;
}









.bridge-guide-text {
    font-size: 1.25rem;
    font-weight: 600;
    color: #ffffff;
  }
  
  .timeline-bridge {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: relative;
  }
  
  .timeline-bridge .li {
    position: relative;
    padding-left: 40px; /* Ensure proper space for dots */
    margin-bottom: 20px;
  }
  
  .timeline-bridge .li::before {
    content: '';
    position: absolute;
    left: 15px; /* Align with the dot */
    top: 0;
    height: 100%;
    width: 2px;
    background-color: grey;
    z-index: 0; /* Ensure it stays behind the content */
  }
  
  .timeline-bridge .li.active::before {
    background-color: #4cd0cd;
  }
  
  .timeline-bridge .status {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .timeline-bridge .status::before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: grey;
    border: 1px solid #aaa5eb;
    position: absolute;
    left: -30px; /* Position the dot correctly */
    z-index: 1; /* Ensure it stays above the connector */
  }
  
  .timeline-bridge .li.active .status::before {
    background-color: #4cd0cd;
    border-color: #4cd0cd;
  }
  
  .timeline-wrapper-item {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2; /* Ensure content stays above the connector */
  }
  
  .listtext {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    color: #ffffff;
  }
  
  .status-desc {
    font-size: 0.875rem;
    color: #a0a0a0;
  }


  .stepsborder {
    background: #070D27;
    border-radius: 15px;
    padding: 10px;
    border: 1px solid #141b44;
  }
  .marketmintnewtitle-marked {
    color: #09FBD3 !important;
}

  .listtext {
    color: #c0cbf7 !important;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.18px;
    position: relative;
    margin: 0;
}

.status-desc {
    color: #c0cbf7 !important;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.18px;
}

  .greendot {
    background-color: #4cd0cd !important;
    border-color: #4cd0cd !important;
  }
  
  .passivedot {
    background-color: transparent !important;
    border-color: #aaa5eb !important;
  }
  
  .greenline {
    background-color: #4cd0cd !important;
  }
  
  .passiveline {
    background-color: grey !important;
  }
  