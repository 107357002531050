.land-main-wrapper {
  gap: 80px;
  max-width: 2400px;
  background-image: url("./siteBackground.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow-x: hidden;

  @media screen and (max-width: 786px) {
    background-size: cover;
  }

  .land-hero-wrapper {
    background-image: url("./landHeroBg.webp");
    background-repeat: no-repeat;
    background-size: 100% 130%;

    @media screen and (max-width: 786px) {
      background-size: cover;
    }
  }

  .land-hero-title {
    font-weight: 800;
    font-size: 32px;
    line-height: 43px;
    color: #ffffff;
  }
  .land-hero-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .benefits-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border: 2px solid #8c56ff;
    backdrop-filter: blur(5.5px);
    border-radius: 12px;
    gap: 40px;

    @media screen and (max-width: 786px) {
      grid-template-columns: auto;
    }

    .benefits-title {
      font-weight: 300;
      font-size: 24px;
      line-height: 36px;
      color: #ffffff;
    }
    .benefits-content {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #c0cbf7;
    }
  }
  .totalcreated {
    background: #7bd8af;
    position: absolute;
    border-radius: 50%;
    padding: 7px 14px;
    top: -17px;
    left: 10px;
    z-index: 1;

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #080b2a;
    }
  }
  .connect-wallet-title {
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
  }

  .mint-span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #d87b7b;
    text-align: center;
  }

  .connect-wallet-wrapper {
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
    border-radius: 8px;
    .connect-wallet-content {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
    }

    .wallet-btn {
      border: 1px solid #7bd8b0;
      border-radius: 8px;
      padding: 10px;
      .wallet-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #7bd8b0;
      }
    }
  }

  .errormsg {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #d87b7b;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .pricetext {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .price-separator {
    height: 1px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: rgba(255, 255, 255, 0.3);
    width: 100%;
  }
  .pricetag {
    right: 20px;
    top: 20px;
    z-index: 1;

    @media screen and (max-width: 991px) {
      right: 30px;
      bottom: 105px;
      top: auto;
    }
  }

  .totalprice {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
  }

  .mintprice {
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  .mint-wrapper {
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
    border-radius: 8px;
    position: relative;
    .land-stake-title {
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
      position: absolute;
      white-space: pre;
      left: 0;
      top: -40px;
      @media screen and (max-width: 786px) {
        top: -70px;
        white-space: break-spaces;
      }
    }
    .create-land-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }

    .more-info {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #ffffff;
    }

    .land-placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
    }

    .land-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }

    .mint-divider {
      width: 100% !important;
      border-top: 1px solid #4f4770 !important;
      opacity: 1 !important;
    }
  }

  .mint-wrappernew {
    background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
    border-radius: 8px;
    position: relative;

    @media screen and (max-width: 991px) {
      background-image: url("./newGenesisBg.webp");
      background-size: cover;
      background-repeat: no-repeat;
    }

    .land-stake-title {
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
      position: absolute;
      white-space: pre;
      left: 0;
      top: -40px;
      @media screen and (max-width: 991px) {
        top: -95px;
        white-space: break-spaces;
      }
    }

    .land-stake-titlenew {
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
      color: #ffffff;
      position: relative;
      // white-space: pre;
      @media screen and (max-width: 786px) {
        font-size: 18px;
      }
    }

    .land-lock-timenew {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
      position: relative;
    }

    .cawsimg {
      height: 140px;
      object-fit: contain;
      width: fit-content;
    }

    .marktext {
      color: #09fbd3;
      font-weight: bold;
      background-color: transparent;
    }

    .whitelist-desc {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
    }
    .minting-price {
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 18px;
      color: #09fbd3;
    }

    .minthero {
      width: 100%;
      position: absolute;
      top: 0;
      height: 100%;
      object-fit: cover;
      display: none !important;
    }
    .create-land-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }

    .more-info {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #ffffff;
    }

    .land-placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
    }

    .land-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }

    .mint-divider {
      width: 100% !important;
      border-top: 1px solid #4f4770 !important;
      opacity: 1 !important;
    }
  }

  .mint-wrappernew::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    // background: linear-gradient(126.67deg, #181636 35.2%, #7133e5 191.04%);
    background-image: url("./genesis-hero.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;

    @media screen and (max-width: 991px) {
      background-image: none;
    }
  }

  // .mint-wrappernew::after{
  //   content: "";
  //   background-image: url('./genesis-hero.png');
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: center;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   bottom: 0;
  //   right: 0;
  //   margin: auto;
  // }

  .land-input {
    background: #272450;
    border: 1px solid #aaa5eb;
    border-radius: 8px;
    padding: 15px;
    color: #ffffff;
  }

  .land-input::placeholder {
    color: #ffffff;
  }

  .limit-span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    position: absolute;
    left: 0;
    bottom: -30px;
  }

  .eth-price {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }

  .land-apr {
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    white-space: pre;
  }

  .land-lock-time {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    width: 75%;
    padding-bottom: 5px;
    
    @media screen and (max-width: 1500px) {
      width: 100%;
    }
  }

  .earned-span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #8c56ff;
  }

  .eth-rewards {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }

  .genesis-desc {
    width: 100%;
    background: linear-gradient(180deg, #5b08d0 -17.02%, #7f2df7 100%);
    border-radius: 6px;
    --mask: linear-gradient(45deg, #0000 30px, #000 0);
    -webkit-mask: var(--mask);
    mask: var(--mask);
    padding: 30px;
    // position: absolute;
    // top: 80%;
  }

  .land-desc {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #f7f7fc;

    @media screen and (max-width: 1300px) {
      display: flex;
      white-space: nowrap;
    }
    @media screen and (max-width: 1080px) {
      display: flex;
      white-space: normal;
    }

    @media screen and (max-width: 434px) {
      display: flex;
      white-space: nowrap;
    font-size: 12px;

    }
  }

  .genesis-badge {
    // position: absolute;
    // left: 0;
    // right: 0;
    // margin: auto;
    // top: 30px;
    width: 100%;
  }

  .tiers-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 40px;
    // margin-bottom: 25rem;
    @media screen and (max-width: 1300px) {
      grid-template-columns: repeat(3, 1fr);
      // place-items: center;
    }
    @media screen and (max-width: 786px) {
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 1rem;
    }
  }

  .genesis-wrapper {
    --mask: linear-gradient(-150deg, #0000 36.57px, #000 0);
    -webkit-mask: var(--mask);
    mask: var(--mask);
    background: linear-gradient(
      141.14deg,
      #5e17cc 11.06%,
      #381486 34.42%,
      #1f164c 56.62%
    );
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.32);
    border-radius: 6px;
    height: 400px;
  }

  .genesis-wrapper:nth-child(even) {
    position: relative;
    // top: 80px;
  }

  .land-tiers {
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    white-space: pre;

    @media screen and (max-width: 786px) {
      white-space: normal;
    }
  }

  .tiers-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    width: 50%;
    @media screen and (max-width: 786px) {
      width: 100%;
      padding: 0 10px;
    }
  }

  .benefit-card {
    background: linear-gradient(300.5deg, #ba7bd8 2.36%, #85fbc9 82.19%);
    border-radius: 6px;
    --mask: linear-gradient(45deg, #0000 30px, #000 0);
    -webkit-mask: var(--mask);
    mask: var(--mask);
    padding: 30px;
    width: 350px;
    .benefit-title {
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;
      text-align: right;
      color: #080b2a;
    }
  }

  .glass-card {
    background: rgba(255, 255, 255, 0.26);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 25%;
    height: 200px;
    position: absolute;
    bottom: 250px;
    .benefits-building {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: -150px;
    }
  }

  .mint-time {
    font-family: "Organetto" !important;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 54px;
    color: #ffffff;

    @media screen and (max-width: 786px) {
      font-size: 30px;
    }
  }

  .days {
    font-family: "Organetto";
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    @media screen and (max-width: 786px) {
      font-size: 10px;
    }
  }

  .community-title {
    font-weight: 800;
    font-size: 32px;
    line-height: 43px;
    color: #ffffff;
    white-space: pre;
    @media screen and (max-width: 786px) {
      white-space: normal;
    }
  }

  .community-card {
    background: #181636;
    border-radius: 8px;
    height: 500px;

    .community-title {
      font-weight: 800;
      font-size: 20px;
      line-height: 27px;
      color: #ffffff;
    }

    .community-desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff !important;
      max-height: 90px;
      text-decoration: none !important;
      p {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #ffffff !important;
        margin-bottom: 0 !important;
      }
      span {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #ffffff !important;
        margin-bottom: 0 !important;
      }

      h6 {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        color: #ffffff !important;
        margin-bottom: 0 !important;
      }
    }

    .community-date {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.018em;
      color: #f7f7f7;
      text-decoration: none !important;
    }
  }

  .tier-specs {
    background: rgba(0, 0, 0, 0.32);
    border-radius: 4px;
    .spec-value {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
    }
    .spec-type {
      font-weight: 400;
      font-size: 10px;
      line-height: 18px;
      color: rgba(255, 255, 255, 0.7);
    }
  }
  .land-benefits-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }

  .tier-benefit {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
  }

  .genesis-land {
    background-image: url("./landNft.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .community-image {
    --mask: linear-gradient(-135deg, #0000 30px, #000 0);
    -webkit-mask: var(--mask);
    mask: var(--mask);
    height: 300px;
    object-fit: cover;
    cursor: pointer;
  }

  .twitter-tag {
    position: absolute;
    left: 0;
    top: 0;
  }

  .mint-release-date {
    font-weight: 800;
    font-size: 28px;
    line-height: 38px;
    color: #ffffff;

    @media screen and (max-width: 786px) {
      font-size: 22px;
    }
  }

  .mint-release-title {
    width: 75%;

    @media screen and (max-width: 786px) {
      width: 100%;
    }

    span {
      font-weight: 800;
      font-size: 24px;
      line-height: 40px;
      color: #181637;
      background-color: #09fbd3;
      @media screen and (max-width: 786px) {
        font-size: 20px;
      }
    }
  }

  .mint-types {
    background: rgba(0, 0, 0, 0.32);
    border-radius: 4px;
    width: 75%;
    flex-wrap: wrap;
    gap: 12px;
    @media screen and (max-width: 1500px) {
      width: 100%;
    }
    .type-title {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
    }
    .type-desc {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #09fbd3;
    }

    .dimensions-span {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color: rgba(255, 255, 255, 0.75);
    }
  }

  .mint-benefits-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;

    @media screen and (max-width: 786px) {
      gap: 15px;
    }
    .mint-benefits-title {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
    }
  }

  .members-title {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;

    color: #ffffff;
  }

  .glass-bubble {
    background: rgba(255, 255, 255, 0.03);
    box-shadow: inset 0px 5.48547px 5.48547px rgba(255, 255, 255, 0.16),
      inset 0px 0px 43.8838px rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(18.5135px);
    border-radius: 50%;
    width: fit-content;
  }

  .first-glass {
    width: 235px;
    height: 235px;
    @media screen and (max-width: 1171px) {
      min-width: 200px !important;
      min-height: 200px !important;
      max-width: 200px !important;
      max-height: 200px !important;
    }
  }

  .second-glass {
    width: 173px;
    height: 173px;
  }
  .third-glass {
    width: 235px;
    height: 235px;
    @media screen and (max-width: 1171px) {
      min-width: 200px !important;
      min-height: 200px !important;
      max-width: 200px !important;
      max-height: 200px !important;
    }
  }
  .fourth-glass {
    width: 173px;
    height: 173px;
  }

  .first-bubble {
    position: absolute;
    left: 15%;
    top: 27%;
    z-index: 2;

    @media screen and (max-width: 1171px) {
      top: 38%;
      left: 6%;
    }

    .members-playing {
      position: absolute;
      left: -1%;
      top: -3%;
      z-index: 3;

      @media screen and (max-width: 1171px) {
        left: 0%;
        top: -15%;
        z-index: 3;
        scale: 0.8;
      }
    }
  }
  .second-bubble {
    position: absolute;
    left: 22%;
    top: 62%;
    z-index: 3;
    @media screen and (max-width: 1171px) {
      left: 10%;
      top: 76%;
    }
  }
  .third-bubble {
    position: absolute;
    left: 70%;
    top: 27%;
    @media screen and (max-width: 1171px) {
      left: 71%;
      top: 39%;
    }

    .discord-members {
      position: absolute;
      left: 7%;
      top: -20%;
      z-index: 3;
      @media screen and (max-width: 1171px) {
        left: 7%;
        top: -20%;
        z-index: 3;
        scale: 0.8;
      }
    }
  }
  .fourth-bubble {
    position: absolute;
    left: 67%;
    top: 62%;

    @media screen and (max-width: 1171px) {
      left: 68%;
      top: 76%;
    }

    .twitter-members {
      position: absolute;
      left: -1%;
      top: -20%;
      z-index: 3;

      @media screen and (max-width: 1171px) {
        left: -1%;
        top: -20%;
      }
    }
  }

  .pink-title {
    font-weight: 800;
    font-size: 34px;
    line-height: 37px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 0px 37.3867px rgba(241, 28, 220, 0.52),
      0px 0px 15.7418px rgba(241, 28, 220, 0.48);
    filter: blur(0.491931px);
  }

  .pink-content {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 0px 37.3867px rgba(241, 28, 220, 0.52),
      0px 0px 15.7418px rgba(241, 28, 220, 0.48);
    filter: blur(0.491931px);
  }
  .blue-title {
    font-weight: 800;
    font-size: 34px;
    line-height: 37px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 0px 37.3867px rgba(88, 101, 242, 0.52),
      0px 0px 15.7418px rgba(88, 101, 242, 0.48);
    filter: blur(0.491931px);
  }

  .blue-content {
    font-weight: 300;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 0px 37.3867px rgba(88, 101, 242, 0.52),
      0px 0px 15.7418px rgba(88, 101, 242, 0.48);
    filter: blur(0.491931px);
  }

  .play-button {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    pointer-events: none;
    //   padding: 15px;
    //   box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.25), inset 0px 5.48547px 5.48547px rgba(255, 255, 255, 0.16), inset 0px 0px 43.8838px rgba(255, 255, 255, 0.08);
    // backdrop-filter: blur(18.5135px);
    // background: rgba(255, 255, 255, 0.03);
  }

  .slider-buttons-wrapper {
    position: absolute;
    bottom: -10%;
    right: 2.5%;
    width: fit-content;
    @media screen and (max-width: 786px) {
      right: 3.5%;
      bottom: -10%;
    }
  }

  .prev-button {
    transform: rotate(180deg);
    z-index: 2;
    margin: auto;
    width: fit-content;
    cursor: pointer;
  }
  .next-button {
    z-index: 2;
    margin: auto;
    width: fit-content;
    cursor: pointer;
  }

  .new-benefits-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    place-items: center;

    @media screen and (max-width: 1500px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 786px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .new-benefits-header {
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #8c56ff;
  }

  .new-benefit-card {
    background-image: url("./benefitBg.svg");
    background-size: 350px 150px;
    background-repeat: no-repeat;
    background-position: center;
    padding: 30px;
    min-width: 350px;
    max-width: 350px;

    @media screen and (max-width: 1600px) {
      background-size: 300px 150px;
      min-width: 300px;
      max-width: 300px;
    }
    @media screen and (max-width: 1500px) {
      background-size: 350px 150px;
      min-width: 350px;
      max-width: 350px;
    }
    .new-benefit-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #ffffff;
    }
    .new-benefit-desc {
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #ffffff;
    }
  }

  .land-benefits-wrapper {
    background-image: url("./landBenefitsBg.svg");
    background-repeat: no-repeat;
    background-size: 75% 75%;
    background-position: center;
  }
}

.landtitle-wrapper {
  white-space: initial;
  display: block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.newminttitle {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
}


.newminttitlehome {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.1px;
}

.flex-wrapper {
  flex: 1
}

.newminttitle-marked {
  color: #09FBD3;
}
.marketmintnewtitle {
  color: #FFF;
font-size: 28px;
font-weight: 800;
}

.marketmintnewtitle-marked {
  color: #09FBD3;
}

@media screen and (max-width: 1441px) {
  .benefit-card {
    width: 285px !important;
  }

  .glass-card {
    bottom: 300px !important;
  }
}

@media screen and (max-width: 786px) {
  .genesis-wrapper:nth-child(4) {
    padding-bottom: 100px !important;
  }

  .laptop {
    width: 50% !important;
  }

  .land-main-wrapper .first-bubble {
    top: 26%;
    left: -14%;
    scale: 0.6;
  }

  .land-main-wrapper .second-bubble {
    left: -6%;
    top: 45%;
    scale: 0.7;
  }

  .land-main-wrapper .third-bubble {
    left: 56%;
    top: 26%;
    scale: 0.6;
  }

  .land-main-wrapper .fourth-bubble {
    left: 58%;
    top: 45%;
    scale: 0.7;
  }
}

@media screen and (max-width: 598px) {
  .land-main-wrapper .first-bubble {
    top: 44%;
    left: -22%;
    scale: 0.5;
  }

  .land-main-wrapper .second-bubble {
    left: -19%;
    top: 64%;
    scale: 0.6;
  }

  .land-main-wrapper .third-bubble {
    left: 46%;
    top: 42%;
    scale: 0.5;
  }

  .land-main-wrapper .fourth-bubble {
    left: 51%;
    top: 63%;
    scale: 0.6;
  }
  .land-main-wrapper .members-title {
    font-size: 17px;
  }
}

@media screen and (max-width: 488px) {
  .land-bg {
    width: 100%;
  }

  .laptop {
    width: 75% !important;
  }
}

@media screen and (max-width: 1827px) {
  .discount-break {
    display: none;
  }
}
