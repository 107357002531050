@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
 
@media (max-width: 768px) { 
#domainPreview {
  min-width: 300px;   
  min-height: 200px;  
  width: 100%;        
  height: auto;       
}
}
 

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .pulse {
    animation: pulse 2s infinite;
    transition: transform 0.8s ease-in-out;  
  }
  .rotated-line-through {
    position: relative;
    color: white; /* Text color */
    display: inline-block; /* Necessary for positioning the pseudo-element correctly */
  }
  
  .rotated-line-through::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 42%; /* Align it to roughly the middle of the text */
    height: 1px; /* Line thickness */
    background-color: rgba(255, 0, 0, 0.801); /* Line color */
    transform: rotate(5deg); /* Rotate the line */
    transform-origin: left center; /* Set the origin of rotation */
  }
  

  .toast-error {
    background-color: #f44336; /* Red for error */
    color: white;
  }
  
  .toast-success {
    background-color: #4CAF50; /* Green for success */
    color: white;
  }
  
  .toast-info {
    background-color: #2196F3; /* Blue for info */
    color: white;
  }
  


  .stake-item2 {
    border: 2px solid #141B44;
    border-radius: 12px;
    background: #312f69;
    padding: 8px 30px 8px 12px;
    cursor: pointer;
    /* height: 56px; */
    transition: all 0.25s;
}