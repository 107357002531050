.mycawsCollection {
  background: #312f69;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
  border-radius: 12px;
  padding: 20px;
  margin-top: 14rem;
}

.mycawscollection-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;

  color: #f7f7fc;
}

.gotoNebula-wrapper {
  border-radius: 6px;
  background: linear-gradient(90deg, rgb(167 49 195 / 60%) 33%, rgb(98 57 195 / 60%) 100%);
}

.nebula-wrapper-text {
  color: #f7f7fc;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
}

.ethereumTagwrapper {
  gap: 5px;
  z-index: 1;
  border-radius: 8px;
  border: 0.5px solid #5B75DA;
  background: linear-gradient(95deg, #1E1C40 0%, #5B75DA 100%);
  width: 90px;
  height: 24px;
  transform: translateX(-50%);
  position: absolute;
  top: -15px;
  left: 33%;
}

.baseTagwrapper {
  gap: 5px;
  z-index: 1;
  border-radius: 8px;
  border: 0.5px solid #5B75DA;
  background: linear-gradient(95deg, #1E1C40 0%, #5B75DA 100%);
  width: 95px;
  height: 24px;
  transform: translateX(-50%);
  position: absolute;
  top: -15px;
  left: 33%;
}


.bnbTagwrapper {
  border-radius: 8px;
  border: 0.5px solid #FFD632;
  background: linear-gradient(95deg, #1E1C40 0%, #FFD632 100%);
  z-index: 1;
  transform: translateX(-50%);
  position: absolute;
  top: -15px;
  left: 33%;
  width: 90px;
  height: 24px;
  gap: 5px;

}

.avaxTagWrapper {
  border-radius: 8px;
  border: 0.5px solid #E84142;
  background: linear-gradient(95deg, #1E1C40 0%, #E84142 100%);
  gap: 5px;
  z-index: 1;
  transform: translateX(-50%);
  position: absolute;
  top: -15px;
  left: 33%;
  width: 90px;
  height: 24px;
}

.nebula-bridgebtn {
  border-radius: 6px;
  border: 1px solid #00c899;
  color: #fff;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  padding: 5px 10px;
}

.nebula-bridgebtn:hover {
  text-decoration: underline;
  color: #fff;
}

.cawscontaier {
  position: absolute;
  left: 30%;
  top: -180px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.nft-ethereum-tag {
  background: linear-gradient(261.8deg, #5c76db -1.7%, #8197ee 91.47%);
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
  border-radius: 12px;
  position: absolute;
  left: 3%;
  top: -20px;
}

.nft-ethereum-span {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #f7f7fc;
}

.subscribe-wrapper {
  width: 30%;
  height: fit-content;
  position: fixed;
  left: 0;
  right: 0;
  top: 28%;
  bottom: 0;
  margin: auto;
  box-shadow: 0 0 0 1000px rgb(0 0 0 / 50%);
  z-index: 2;
}

.my-plan-tag {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.4px;
  color: #c0cbf7 !important;
}

.plan-tag {
  background: #312f69;
  border: 1px solid #f5c96c;
  border-radius: 8px;
  /* cursor: pointer; */
}

.plan-tag-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #f7f7fc;
}

.plans-page-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #f7f7fc;
  width: 40%;
}

.plans-page-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #c0c9ff !important;
  width: 40%;
}

.plans-benefits {
  background: #312f69;
  border: 2px solid #8e97cd;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
  border-radius: 12px;
  width: 30%;
  height: 250px;
}

.green-check {
  position: relative;
  bottom: 4px;
}

.plans-benefit-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #f7f7fc;
}

.premium-subscribe-wrapper {
  background: #312f69;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
  border-radius: 12px;
  height: 250px;
}

.success-btn {
  color: #ffffff !important;
  background: linear-gradient(90.74deg, #75cac2 0%, #57b6ab 100%);
  border: none !important;
  border-radius: 8px;
  opacity: 1 !important;
}

.premium-dyp-wrapper {
  position: relative;
  width: 30%;
  height: 200px;
  background: radial-gradient(
    80.22% 120.33% at 47.12% -10.1%,
    #8969d7 0%,
    #482193 100%
  );
  border-radius: 12px;
  display: flex;
  justify-content: end;
  flex-direction: column;
}

.premium-dyp-banner {
  width: 90%;
  position: absolute;
  left: 0px;
  right: 0;
  bottom: 50px;
  margin: auto;
}

.premium-gradient {
  background: linear-gradient(81.57deg, #c42c71 6.19%, #5833a4 87.15%);
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
  border-radius: 12px;
  position: relative;
  top: -50px;
}
.premium-span {
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
}

.premium-price {
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  color: #f7f7fc;
}

.lifetime-subscription {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.018em;
  color: #ffffff;
}

.lifetime-desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.018em;
  color: #ffffff;
}

.dyp-balances-wrapper {
  background: #312f69;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
  border-radius: 12px;
}
.wod-wrapper {
  background-image: url("./assets/wodBackground.png");
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
  border-radius: 12px;
}

.wod-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #f7f7fc;
  width: 50%;
}

.balances-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.dyp-balance-wrapper {
  background: #565891;
  border-radius: 8px;
}

.balance-amount {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}

.cawscontaier-open {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 600px) {
  .cawscontaier,
  .cawscontaier-open {
    grid-template-columns: repeat(1, 1fr);
  }

  .wod-title {
    width: 100%;
  }
  .dyp-balance-wrapper {
    width: 100%;
  }

  .mycawsCollection {
    margin-top: 2rem;
  }

  .subscribe-wrapper {
    width: 95%;
  }
}

@media only screen and (max-width: 756px) {
  .cawscontaier,
  .cawscontaier-open {
    grid-template-columns: repeat(2, 1fr);
  }
  .cawscontaier {
    position: relative;
    left: auto;
    top: auto;
  }
}

.account-username {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #f7f7fc !important;
  white-space: pre;
}

.premium-dyp-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #f7f7fc;
  color: #f7f7fc !important;
  margin-bottom: 10px;
}

.address-span {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.4px;
  color: #c0cbf7 !important;
}

.account-wallet-address {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.018em;
  color: #f7f7fc !important;
}

.clear-icon {
  position: absolute;
  right: 5%;
  top: 0;
  bottom: 0;
  margin: auto;
}

.dyp-amount-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  color: #f7f7fc !important;
}

.account-dyp-amount {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: right;
  color: #f7f7fc !important;
}

.avatar-border {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  border-radius: 50%;
  border: 2px solid #857dfa;
  height: 56px;
  width: 56px;
}

.add-image {
  position: absolute;
  right: 0;
  bottom: 0;
}

.free-plan-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #f7f7fc !important;
}

.free-plan-container {
  background: #312f69;
  border-radius: 12px;
}

.green-border {
  border: 2px solid #50af95;
}

.green-div {
  top: 15px;
  z-index: 1;
  left: 12px;
  background: rgb(142, 151, 205);
}

.free-plan-item:nth-child(odd) {
  background: #565891;
  border-radius: 8px;
}

.free-plan-items-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #f7f7fc !important;
}

.free-plan-image {
  background-image: url("./assets/freePlanBackground.png");
  background-repeat: no-repeat;
  background-position: center;
}
.paid-plan-image {
  background-image: url("./assets/paidPlanBackground.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 150% 145%;
}

.inactive-plan {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #8e97cd !important;
}
.active-plan {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #4fad93 !important;
}

.subscribe-container {
  background: #312f69;
  border: 2px solid #8e97cd;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
  border-radius: 12px;
}

.token-amount-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.018em;
  color: #d6d8e7 !important;
}

.token-balance-placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  letter-spacing: -0.018em;
  color: #4cd0cd !important;
}

.account-token-amount {
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  text-align: right;
  letter-spacing: -0.018em;
  color: #4cd0cd !important;
}

.subscription-token-wrapper {
  background: #2b2a59;
  border-radius: 8px;
}

.usdt-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #f7f7fc !important;
}

.subscribe-input-container {
  width: 40%;
}

.favorites-grid {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);
}

.poolrows {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 991px) {
  .poolrows {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 786px) {
  .poolrows {
    grid-template-columns: repeat(1, 1fr);
  }
  .account-dyp-amount {
    text-align: left;
  }
  .dyp-amount-placeholder {
    text-align: left;
  }
  .subscribe-input-container {
    width: 100%;
  }

  .favorites-grid {
    grid-template-columns: auto;
  }

  .plans-page-title,
  .plans-page-desc,
  .plans-benefits,
  .premium-subscribe-wrapper,
  .premium-dyp-wrapper {
    width: 100% !important;
  }

  .all-plans-wrapper {
    gap: 80px !important;
  }

  .premium-dyp-wrapper {
    margin-top: 4rem !important;
  }

  .premium-dyp-banner {
    width: 90%;
  }
}
