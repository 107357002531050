.selected img {
    animation: spin 6s linear infinite;
    border-radius: 100%;
    box-shadow: 0 0 15px #4455b8; /* Yellow glow for highlight */
}


.bzs {
    transition: box-shadow 0.3s ease;
    border: none; /* Remove default border */
    background: transparent;
    cursor: pointer;
    outline: none; /* Remove outline */
}
 .outlines {
    background: #312f69;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #857dfa;
    border: 1.5px solid #857dfa;
    padding: 8px 24px;
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
 }

 .optionbtn-active {
    background: #1e1c40;
    border: 1px solid #25DAFF !important;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
  }
  
 .explorercard-wrapper {
    padding: 20px;
    background-color: #312f69;
    border-radius: 12px;
    border: 2px solid #312f69;
    background-image: none;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    min-height: 195px;
    /* width: 27%; */
    transition: all 0.25s;
    cursor: pointer;
 }
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.migration-banner-wrapper::before {
    z-index: -1;
    opacity: .3;
}
.migration-banner-wrapper {
    z-index: 1;
}