.custom-toast {
    display: flex;
    align-items: center;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 8px;
  }
  
  .custom-toast svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
  

  
  .info-toast {
    background-color: #1f2937; /* bg-gray-800 */
    color: #60a5fa; /* text-blue-400 */
  }
  
  .error-toast {
    background-color: #1f2937; /* bg-gray-800 */
    color: #f87171; /* text-red-400 */
  }
  
  .success-toast {
    background-color: #1f2937; /* bg-gray-800 */
    color: #34d399; /* text-green-400 */
  }
  
  .warning-toast {
    background-color: #1f2937; /* bg-gray-800 */
    color: #fbbf24; /* text-yellow-300 */
  }
  
  .dark-toast {
    background-color: #1f2937; /* bg-gray-800 */
    color: #d1d5db; /* text-gray-300 */
  }
  