.dapp-topbar {
  width: 100%;
  align-items: center;
  button {
    white-space: nowrap !important;
    overflow: hidden !important;
    margin: 6px !important;
    min-width: fit-content !important;
    &#hamburger {
      padding: 11px !important;
    }
    &.toggle-button {
      padding: 9.5px !important;
    }
    background-color: #d37134!important;
    border-radius: 0!important;

  }
}
.tablet .dapp-topbar {
  justify-content: space-between;
}
.pending-txn-container {
  display: flex;
  align-items: center;
  .MuiButton-label {
    margin-right: 0;
    transition: all 0.33s ease-out;
  }
}
#ohm-popper-pending {
  .MuiPaper-root.ohm-menu {
    a {
      padding-right: 20px !important;
    }
  }
}
.caret-down {
  position: absolute;
  right: 0.4rem;
}
.hovered-button {
  .MuiButton-label {
    margin-right: 0.5rem;
    transition: all 0.33s ease-out;
  }
}

.header-menu-div {
  z-index: 999999 !important;
  box-shadow: 8px 8px 24px rgb(9 13 20 / 40%), -4px -4px 8px rgb(224 224 255 / 4%), 0 1px 1px rgb(9 13 20 / 40%);
  background: linear-gradient(135deg,rgba(59,62,66,.4) 0%,rgba(59,62,66,.4) 50%,rgba(33,36,41,.4) 75%);
  backdrop-filter: blur(10px);
}