.selected img {
  animation: spin 6s linear infinite;
  border-radius: 100%;
  box-shadow: 0 0 15px #4455b8; /* Yellow glow for highlight */
}



.bzs {
  transition: box-shadow 0.3s ease;
  border: none; /* Remove default border */
  background: transparent;
  cursor: pointer;
  outline: none; /* Remove outline */
}

.heXTyF {
  animation: 3s ease-in-out 0s infinite normal none running elgpeR;
}
.kFsaVr {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 370px) {
  .kpeGcW {
      width: 288px;
      height: 113px;
  }

  .migration-banner-wrapper3 {
      width: 100%;
    }
}

@media screen and (max-width: 768px) {
  .migration-banner-wrapper3 {
      width: 100% !important;
    }
}

.cJimZN {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-4deg);
}

.RrxyI {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: rotate(-4deg);
}

@supports (-webkit-text-size-adjust: none) and (not (-ms-accelerator: true)) and (not (-moz-appearance: none)) {
      .ktVOoD {
          filter: none !important;
      }
  }
  
  .ktVOoD {
      align-self: center;
      fill: var(--colors-text);
      color: var(--colors-text);
      flex-shrink: 0;
  }


  .bvJmG {
      background: linear-gradient(rgb(118, 69, 217) 0%, rgb(69, 42, 122) 100%);
      width: 200px;
  }
.warning-txt {
  color: #ffb237 !important;
}

.migration-banner-title2 {
  color: #f7f7fc;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 116.667% */
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(-10deg); /* Start from -10 degrees */
  }
  25% {
    transform: rotate(0deg); /* Rotate to 0 degrees */
  }
  50% {
    transform: rotate(10deg); /* Rotate to 10 degrees */
  }
  75% {
    transform: rotate(0deg); /* Rotate back to 0 degrees */
  }
  100% {
    transform: rotate(-10deg); /* Rotate back to -10 degrees */
  }
}

.animated-element {
  animation: rotateAnimation 4s cubic-bezier(0.25, 0.1, 0.25, 1) infinite; /* Adjust duration and timing function */
}


  .xiIkU {
      position: relative;
      align-items: center;
      border: 0px;
      border-radius: 16px;
      box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset;
      cursor: pointer;
      display: inline-flex;
      font-family: inherit;
      font-size: 16px;
      font-weight: 600;
      justify-content: center;
      letter-spacing: 0.03em;
      line-height: 1;
      opacity: 1;
      outline: 0px;
      transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
      height: 48px;
      padding: 0px 24px;
      background-color: var(--colors-primary);
      color: var(--colors-invertedContrast);
  }

/* Confetti Button Styles */
.confetti-button {
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 2rem;
  margin: 1rem;
  font-weight: bold;
  font-size: 1rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: white;
  background: linear-gradient(-45deg, hsl(260, 95%, 75%), hsl(300, 95%, 75%));
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.8);
  text-shadow: -1px -1px rgba(0, 0, 0, 0.6);
  transition: transform 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

.confetti-button:hover {
  background: linear-gradient(-45deg, hsl(260, 95%, 65%), hsl(300, 95%, 65%));
}

.confetti-button:active {
  transform: translate(2px, 2px);
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.8);
}

/* Celebratory Banner Styles */
.ticket-banner {
  position: relative;
  width: 100%;
  max-width: 700px;
  margin: auto;
  padding: 20px;
  background: linear-gradient(135deg, #ffecd2 0%, #fcb69f 100%);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  border: 2px solid #ff6347;
  text-align: center;
  z-index: 2;
  overflow: hidden;
}

.ticket-content {
  position: relative;
  z-index: 2;
}

.ticket-header {
  font-size: 3rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.ticket-info {
  font-size: 1.5rem;
  font-weight: 500;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.ticket-amount {
  font-size: 2.5rem;
  font-weight: bold;
  color: #e74c3c;
  margin-bottom: 1.5rem;
}

.ticket-button {
  font-size: 1.25rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #27ae60;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.ticket-button:hover {
  background-color: #2ecc71;
}


.kpeGcW {
  width: 240px;
  height: 94px;
  position: relative;
}
.as8888 {
  width: 120px;
  height: 46px;
  color:#312f69;
  position: relative;
}

.ball {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffcc00;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}


.outlines {
  background: #312f69;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #857dfa;
  border: 1.5px solid #857dfa;
  padding: 8px 24px;
  transition: all ease 0.4s;
  -webkit-transition: all ease 0.4s;
}
.explorercard-wrapper {
  padding: 20px;
  background-color: #312f69;
  border-radius: 12px;
  border: 2px solid #312f69;
  background-image: none;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  min-height: 195px;
  /* width: 27%; */
  transition: all 0.25s;
  cursor: pointer;
}
@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

.migration-banner-wrapper::before {
  z-index: -1;
  opacity: .3;
}
.migration-banner-wrapper {
  z-index: 1;
}
.ticket-container {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.migration-banner-wrapper3 {
  position: relative;
  background-color: #312f69;
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 12px;
  padding: 6px;
  border: 2px solid #312f69;
  /* background-image: url(./assets/calculatorbg.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 49%;
  transition: all 0.25s;
}