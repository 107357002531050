.launch-project {
  background: #312f69;
  border-radius: 12px;
  background-image: url("./assets/launchProjectBackground.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 95px !important;
}

.launch-project-title {
  font-weight: 600;
  font-size: 28px;
  line-height: 44px;
  color: #f7f7fc;
  text-align: right;
  width: 50%;
}

.benefits-card {
  position: relative;
  background: linear-gradient(94.69deg, #312f69 0.66%, #5953d9 100%);
  box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.12);
  border-radius: 12px;
}

.tier-levels-title{
  width: 50%;
}

.icon-wrapper {
  background: linear-gradient(315deg, #554fd8 0%, #7770e0 100%);
  border-radius: 8px;
  min-width: 48px;
  min-height: 48px;
}

.benefits-card-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 35px;
}

.benefits-card:nth-child(even) {
  top: 20px;
}

.benefits-desc{
  font-weight: 500;
font-size: 14px;
line-height: 21px;

color: #D6D8E7 !important;
}


.features-wrapper{
  width: 90%;
}

@media screen and (max-width: 1399px) {
 
  .features-wrapper{
    width: 100%;
  }

  .benefits-card-container {
  grid-template-columns: repeat(1, 1fr);
  gap: 15px;
}

.benefits-card:nth-child(even) {
  top: 0px;
}

}

@media screen and (max-width: 1200px) {
  .launch-project-title{
    width: 100%;
  }
  .tier-levels-title{
    width: 100%;
  }
}